<template>
  <div class="media-zoom">
    <span class="media-zoom__close flex middle-xs pointer" @click="$emit('close')">
      <span class="hidden-xs-and-tablet uppercase cl-alternative">{{ $t('zamknij podgląd') }}</span>
      <close-svg class="p12 pointer close-gallery-overlay" height="100%" color="#041D59" />
    </span>
    <no-ssr>
      <product-gallery-zoom-carousel
        v-if="showProductGalleryZoomCarousel"
        :current-slide="currentSlide"
        :gallery="gallery"
        :product-name="productName"
        :show-product-image="showProductImage"
      />
    </no-ssr>
  </div>
</template>
<script>
import NoSSR from 'vue-no-ssr'
import CloseSvg from 'theme/components/theme/blocks/Icons/CloseSvg.vue'
const ProductGalleryZoomCarousel = () => import(/* webpackChunkName: "vsf-product-gallery-zoom-carousel" */ './ProductGalleryZoomCarousel.vue')

export default {
  name: 'ProductGalleryOverlay',
  props: {
    currentSlide: {
      type: Number,
      required: false,
      default: 0
    },
    gallery: {
      type: Array,
      required: true
    },
    showProductImage: {
      type: Boolean,
      required: false,
      default: false
    },
    productName: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      showProductGalleryZoomCarousel: false
    }
  },
  components: {
    'no-ssr': NoSSR,
    ProductGalleryZoomCarousel,
    CloseSvg
  },
  mounted () {
    this.$store.commit('ui/setOverlay', true)
    this.showProductGalleryZoomCarousel = true
  },
  destroyed () {
    this.$store.commit('ui/setOverlay', false)
  }
}
</script>
<style lang="scss" scoped>
@import '~theme/css/base/global_vars';
$z-index-gallery: map-get($z-index, overlay) + 1;

.close-gallery-overlay {
  width: 18px;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    width: 26px;
  }
}
.media-zoom {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z-index-gallery;
  background: #fff;

  &__close {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
      bottom: 0;
      top: unset;
    }
  }
}
</style>
